.greeting {
    position: relative;
    z-index: 1;
    padding-top: 195px;
    padding-bottom: 105px;
    overflow: hidden;
    &__inner {
        position: relative;
        z-index: 1;
    }
    &__advantage {
        align-items: center;
        margin-bottom: 52px;
        &-icon {
            flex-shrink: 0;
            margin-right: 13px;
        }
        &-title {
            color: #fff;
            span {
                display: block;
                color: rgb(var(--manatee));
            }
        }
    }
    &__title {
        margin-bottom: 25px;
        font-size: 60px;
        font-weight: 800;
        text-align: center;
        text-shadow: 0 15px 17px rgba(33,35,45,.3);
        color: #fff;
        span {
            display: block;
            color: rgb(var(--dandelion));
        }
    }
    &__mountain {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        img {
            width: inherit;
        }
    }
}

@media (max-width: 1300px) {
    .greeting {
        padding-top: 160px;
    }
}

@media (max-width: 1080px) {
    .greeting {
        padding-top: 120px;
    }
}

@media (max-width: 992px) {
    .greeting {
        &__title {
            margin-bottom: 36px;
            font-size: 48px;
        }
        &__mountain {
            left: calc(50% - 496px);
            width: 992px;
        }
    }
}

@media (max-width: 767px) {
    .greeting {
        &__advantage {
            margin-bottom: 40px;
        }
        &__title {
            font-size: 40px;
        }
    }
}

@media (max-width: 420px) {
    .greeting {
        padding-top: 80px;
        padding-bottom: 80px;
        &__title {
            font-size: 32px;
        }
        &__mountain {
            left: calc(50% - 420px);
            width: 840px;
        }
    }
}